import React from "react";
import resumeFile from "../documents/Ali_Khan_Resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2000 - 2004",
      title: "Computer Science",
      place: "International University",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2005 - 2008",
      title: "Bachelor Degree",
      place: "University of California",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
    {
      yearRange: "2009 - 2012",
      title: "Master Degree",
      place: "Harvard University",
      desc: "Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "May 2023 - June 2023",
      title: "Technical Director",
      place: "Super League Gaming, Santa Monica, California, United States (Remote)",
      desc: "Overseeing multiple engineering teams working on various Roblox game development projects.",
    },
    {
      yearRange: "Oct 2022 - April 2023",
      title: "Senior Technical Director",
      place: "Melon Inc, Orlando, Florida, United States (Remote)",
      desc: "Overseeing the engineering team working across multiple projects. Implementing processes to ensure optimized and timely deliveries to clients.",
    },
    {
      yearRange: "Nov 2020 - Oct 2022",
      title: "Senior Unity Developer",
      place: "Intel Corporation, Santa Clara, California, United States (Remote)",
      desc: "Developed tools to automate internal design processes and optimize analysis of 3D geometry through GPU computations.",
    },
	{
      yearRange: "May 2020 - Jan 2021",
      title: "Roblox Game Developer",
      place: "GameFam, West Hollywood, California, United States (Remote)",
      desc: "Worked on GameFam's flagship game Ultimate Driving as well as upcoming games and projects..",
    },
	{
      yearRange: "Dec 2007 - May 2020",
      title: "Cofounder and Lead Programmer",
      place: "Urchin Games, Islamabad, Pakistan",
      desc: "Urchin Games develops PC and mobile games which are released by some of the largest casual games publishers in the world.",
    },
	{
      yearRange: "May 2006 - Nov 2007",
      title: "Senior Software Engineer",
      place: "Prosol Technologies, Islamabad, Pakistan",
      desc: "Worked as part of a game development team working on serious games and training simulations.",
    },
	{
      yearRange: "Jan 2005 - Apr 2006",
      title: "Software Engineer",
      place: "Fork Particle, United States (Remote)",
      desc: "Cross-platform 3D particle rendering systems and related IDE development.",
    },
  ];

  const skills = [
    {
      name: "Unity3D",
      percent: 90,
    },
	{
      name: "Roblox",
      percent: 90,
    },
	{
      name: "Game Producer",
      percent: 80,
    },
	{
      name: "Game Design",
      percent: 80,
    },
	{
      name: "C++",
      percent: 80,
    },
    {
      name: "C#",
      percent: 80,
    },
	{
      name: "GPU Programming",
      percent: 70,
    },
    {
      name: "Lua",
      percent: 70,
    },
	{
      name: "Git",
      percent: 60,
    },
    {
      name: "Jira",
      percent: 60,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Experience
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {
			experienceDetails.length > 0 &&
			experienceDetails.map((value, index) => (
			<div
			  key={index}
			  className={
				"bg-white  rounded p-4 mb-4 " +
				(darkTheme ? "bg-dark" : "bg-white border")
			  }
			>
			  <p className="badge bg-primary text-2 fw-400">
				{value.yearRange}
			  </p>
			  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
				{value.title}
			  </h3>
			  <p className={darkTheme ? "text-primary" : "text-danger"}>
				{value.place}
			  </p>
			  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
				{value.desc}
			  </p>
			</div>
		  ))
		}
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
