import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    PRODUCER: "Producer",
    TECHDIRECTOR: "Technical_Director",
    PROGRAMMER: "Lead_Programmer",
	DESIGNER: "Game_Designer",
  };

  const projectsData = [
    {
      title: "Mysteries of Neverville",
      projectInfo: "The runestone of light that has protected the town of Neverville for thousands of years is missing. With nothing to protect the town, an evil fog has surrounded the people inside. You play as the daughter of Cygnus Rastlin, the greatest guardian of Neverville. Find out who is lurking in the fog! Is it the notorious necromancer Grimhall? Will you be able to save the innocent people of Neverville from their grim fate?",
      role: "Lead Programmer",
      technologies: "C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "June, 2014",
      url: {
        name: "Mysteries of Neverville",
        link: "https://www.bigfishgames.com/us/en/games/8801/mysteries-of-neverville-runestone-of-light/?pc&lang=en",
      },
      thumbImage: "images/projects/neverville/main.png",
      sliderImages: [
        "images/projects/neverville/sc_1.jpg",
		"images/projects/neverville/sc_2.jpg",
		"images/projects/neverville/sc_3.jpg",
      ],
      categories: [filters.PROGRAMMER],
    },
	{
      title: "American Girl",
      projectInfo: "Explore The American Girl World! Shop for an ever-expanding assortment of items inspired by our stories to make you and your character stand out. Grab up a tennis racket or skateboard and help your team win in a Tile Takeover showdown!",
      role: "Technical Director",
      technologies: "Roblox, Lua",
      publishers: "Melon, Mattel, Roblox",
      date: "March, 2023",
      url: {
        name: "American Girl on Roblox",
        link: "https://web.roblox.com/games/12429783238/UPDATE-American-Girl-World",
      },
      thumbImage: "images/projects/americangirl/main.png",
      sliderImages: [
        "images/projects/americangirl/sc_1.jpg",
		"images/projects/americangirl/sc_2.jpg",
		"images/projects/americangirl/sc_3.jpg",
      ],
      categories: [filters.TECHDIRECTOR],
    },
	{
      title: "Greed: Forbidden Experiments",
      projectInfo: "Julia is surrounded by unusual circumstances after she receives a mysterious phone call. The caller asks Julia to rescue her twin sister Sophia, who was abducted many years ago. Is the caller a sincere informer or is he trying to lure Julia into a trap? Find out the sinister plans of the secret cult of the Oracles and stop their forbidden experiments.",
      role: "Producer",
      technologies: "Unity, C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "March, 2015",
      url: {
        name: "Greed: Forbidden Experiments",
        link: "https://www.bigfishgames.com/us/en/games/8550/greed-forbidden-experiments?pc&lang=en",
      },
      thumbImage: "images/projects/forbiddenexperiments/main.png",
      sliderImages: [
        "images/projects/forbiddenexperiments/sc_1.jpg",
		"images/projects/forbiddenexperiments/sc_2.jpg",
		"images/projects/forbiddenexperiments/sc_3.jpg",
      ],
      categories: [filters.PRODUCER],
    },
	{
      title: "Dave and Busters",
      projectInfo: "Check the Arcade, grab a snack and use it whenever you need a boost! Collect hidden boosts or grab them from the concession, and keep an eye out for fun secrets! Exchange winning tickets for in-game rewards in the Winner’s Circle!",
      role: "Technical Director",
      technologies: "Roblox, Lua",
      publishers: "Dave and Busters, Melon, Roblox",
      date: "April, 2023",
      url: {
        name: "Dave and Busters World on Roblox",
        link: "https://web.roblox.com/games/11434430883/NEW-GAME-DAVE-BUSTER-S-WORLD",
      },
      thumbImage: "images/projects/daveandbusters/main.png",
      sliderImages: [
        "images/projects/daveandbusters/sc_1.jpg",
		"images/projects/daveandbusters/sc_2.jpg",
		"images/projects/daveandbusters/sc_3.jpg",
      ],
      categories: [filters.TECHDIRECTOR],
    },
	{
      title: "Clarks Playprints",
      projectInfo: "An out-of-this-world adventure filled with exploration and excitement awaits. Welcome to PLAYPRINTS World by Clarks! Step inside the PLAYPRINTS World, where you can unlock magical missions in mystical lands.",
      role: "Technical Director",
      technologies: "Roblox, Lua",
      publishers: "Clarks, Super League Gaming, Roblox",
      date: "May, 2023",
      url: {
        name: "Clarks Playprints",
        link: "https://web.roblox.com/games/13405136473/PLAYPRINTS-World",
      },
      thumbImage: "images/projects/playprints/main.png",
      sliderImages: [
        "images/projects/playprints/sc_1.jpg",
		"images/projects/playprints/sc_2.jpg",
		"images/projects/playprints/sc_3.jpg",
      ],
      categories: [filters.TECHDIRECTOR],
    },
	{
      title: "The Forsaken Isle",
      projectInfo: "There’s an island 30 miles off the coast of England that the locals call 'The Forsaken Isle'. The once bustling port city is now said to be home to ghosts, necromancers and dangerous man-eating wolves! Such nonsense can’t hold any merit, right? Confront an evil warlock, a vicious pack of wolves and other dark forces to escape with your life!",
      role: "Producer",
      technologies: "Unity, C#, C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "April, 2017",
      url: {
        name: "The Forsaken Isle",
        link: "https://store.steampowered.com/app/645410/Ominous_Tales_The_Forsaken_Isle/",
      },
      thumbImage: "images/projects/ominous/main.png",
      sliderImages: [
        "images/projects/ominous/sc_1.jpg",
		"images/projects/ominous/sc_2.jpg",
		"images/projects/ominous/sc_3.jpg",
      ],
      categories: [filters.PRODUCER],
    },
	{
      title: "NFL Quarterback Simulator",
      projectInfo: "Play on unique themed fields, create your team to destroy bigger targets and collect rare NFL cards!",
      role: "Technical Director",
      technologies: "Roblox, Lua",
      publishers: "NFL, Melon, Roblox",
      date: "December, 2022",
      url: {
        name: "NFL Quarterback Simulator",
        link: "https://web.roblox.com/games/11504594758/NFL-QUARTERBACK-SIMULATOR",
      },
      thumbImage: "images/projects/quarterback/main.png",
      sliderImages: [
        "images/projects/quarterback/sc_1.jpg",
		"images/projects/quarterback/sc_2.jpg",
		"images/projects/quarterback/sc_3.jpg",
      ],
      categories: [filters.TECHDIRECTOR],
    },
	{
      title: "Outlaws: Corwin's Treasure",
      projectInfo: "You receive an invitation to visit the old abandoned town of St. Martica by Mr. John Atkins, a rich businessman who promised to share a recent and significant Mayan archeological discovery! Being a historian, you found this opportunity too good to pass. However, when you reached the town, the truth of the situation unraveled when your wife was kidnapped! Now, you are forced to help Atkins and his gang in their quest for the famed Corwin’s Treasure.",
      role: "Producer",
      technologies: "C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "June, 2019",
      url: {
        name: "Outlaws: Corwin's Treasure",
        link: "https://www.bigfishgames.com/us/en/games/14500/outlaws-corwins-treasure-f14500t1l1/?pc",
      },
      thumbImage: "images/projects/outlaws/main.png",
      sliderImages: [
        "images/projects/outlaws/sc_1.jpg",
		"images/projects/outlaws/sc_2.jpg",
		"images/projects/outlaws/sc_3.jpg",
      ],
      categories: [filters.PRODUCER],
    },
	{
      title: "Blink Comet",
      projectInfo: "Software for developing interactive walkthroughs for Blink Property developers.",
      role: "Lead Programmer",
      technologies: "Unity, C#",
      publishers: "Blink Property",
      date: "June, 2019",
      url: {
        name: "Not publicaly available",
        link: "",
      },
      thumbImage: "images/projects/blink/main.png",
      sliderImages: [
        "images/projects/blink/sc_1.jpg",
		"images/projects/blink/sc_2.jpg",
		"images/projects/blink/sc_3.jpg",
      ],
      categories: [filters.PROGRAMMER],
    },
	{
      title: "Scarytales: All Hail King Mongo",
      projectInfo: "The evil sorcerer Zarrar has returned to take vengeance upon your family and the kingdom from which he was banished years ago. After possessing your brother’s mind through his dark magic, Zarrar is now unstoppable. As the princess, you are the only remaining member of the royal family who is safe from Zarrar’s evil scheme.",
      role: "Producer and Game Designer",
      technologies: "C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "March, 2013",
      url: {
        name: "Scarytales: All Hail King Mongo",
        link: "https://www.bigfishgames.com/us/en/games/7560/scarytales-all-hail-king-mongo/?pc&lang=en",
      },
      thumbImage: "images/projects/scarytales/main.png",
      sliderImages: [
        "images/projects/scarytales/sc_1.jpg",
		"images/projects/scarytales/sc_2.jpg",
		"images/projects/scarytales/sc_3.jpg",
      ],
      categories: [filters.DESIGNER, filters.PRODUCER],
    },
	{
      title: "Vengeance: Lost Love",
      projectInfo: "Amanda is stuck in a horrifying ordeal as she and her fiancé James are confronted by the lone miner. She soon realizes that the legend of the lone miner is connected to her family’s past in more ways than she ever imagined. With her brother already involved in suspicious activities, she must rely on the help of an old friend to uncover the secrets behind the strange events unfolding around her.",
      role: "Game Designer",
      technologies: "C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "July, 2016",
      url: {
        name: "Vengeance Lost Love",
        link: "https://store.steampowered.com/app/665510/Vengeance_Lost_Love/",
      },
      thumbImage: "images/projects/vengeance/main.png",
      sliderImages: [
        "images/projects/vengeance/sc_1.jpg",
		"images/projects/vengeance/sc_2.jpg",
		"images/projects/vengeance/sc_3.jpg",
      ],
      categories: [filters.DESIGNER],
    },
	{
      title: "Entwined: The Perfect Murder",
      projectInfo: "Mr. Turner was surrounded by people who could all benefit from his death but who actually murdered him? Play the detective in this classic whodunit mystery!",
      role: "Lead Programmer",
      technologies: "Unity, C#, C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "September, 2014",
      url: {
        name: "Entwined: The Perfect Murder",
        link: "https://www.bigfishgames.com/us/en/games/8848/entwined-the-perfect-murder/?pc&lang=en",
      },
      thumbImage: "images/projects/perfectmurder/main.png",
      sliderImages: [
        "images/projects/perfectmurder/sc_1.jpg",
		"images/projects/perfectmurder/sc_2.jpg",
		"images/projects/perfectmurder/sc_3.jpg",
      ],
      categories: [filters.PROGRAMMER],
    },
	{
      title: "Greed: The Mad Scientist",
      projectInfo: "A world famous scientist Dr. Ralph Goodwin promises to unveil a miracle drug able to cure almost all types of diseases. But just one day before his press conference a mysterious explosion destroys his research laboratory. Among mad scientists and ruthless assassins will you ever be able to find the cure?",
      role: "Producer",
      technologies: "Unity, C#, C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "November, 2015",
      url: {
        name: "Greed: The Mad Scientist",
        link: "https://store.steampowered.com/app/991960/Greed_The_Mad_Scientist/",
      },
      thumbImage: "images/projects/madscientist/main.png",
      sliderImages: [
        "images/projects/MadScientist/sc_1.jpg",
		"images/projects/MadScientist/sc_2.jpg",
		"images/projects/MadScientist/sc_3.jpg",
      ],
      categories: [filters.PRODUCER],
    },
	{
      title: "Entwined: Strings of Deception",
      projectInfo: "Play the role of the detective in this classic whodunit murder mystery. Search the Edward family mansion and question the late Christopher Edward’s wife, butler, housemaid, and even his physician. Everyone’s a suspect!",
      role: "Lead Programmer",
      technologies: "C++, DirectX",
      publishers: "Big Fish Games, Steam, Urchin Games",
      date: "March, 2012",
      url: {
        name: "Entwined: Strings of Deception",
        link: "https://www.bigfishgames.com/us/en/games/7056/entwined-strings-of-deception-f7056t1l1/?pc",
      },
      thumbImage: "images/projects/EntwinedStrings/main.png",
      sliderImages: [
        "images/projects/EntwinedStrings/sc_1.jpg",
		"images/projects/EntwinedStrings/sc_2.jpg",
		"images/projects/EntwinedStrings/sc_3.jpg",
      ],
      categories: [filters.PROGRAMMER],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work As
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
